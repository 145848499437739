.App {
  text-align: center;
  font-color: #444444;
  font-weight: 400;
  background: rgb(35,210,30);
  background: radial-gradient(circle, rgba(35,210,30,1) 0%, rgba(16,117,11,1) 100%, rgba(70,52,134,1) 100%);
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  //background-color: #282c34;
  min-height: 3vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1, h2, h3, h4 {
  color: #ffffff;
  font-weight: 100;
}
h1 {
  font-size: 2em;
}
h2 {
  color: #ffffff;
  font-weight: 300;
  font-size: 2em;
  //letter-spacing: 0.1em;
  padding-top: 0.5em;
}
h3 {
  color: #ffffff;
  font-weight: 500;
}

.join-form {
background-color: white;
border-radius: 20px;
border: 2px solid darkgreen !important;
margin: 10px auto 0px auto !important;
padding: 10px 20px !important;
//width: 90%;
}

.players-in-lobby {
  font-size: 0.8em !important;
  color: blue !important;
  background-color: yellow !important;
  margin: 5px !important;
  font-weight: 600 !important;
  padding: 5px 10px !important;
  border-radius: 10px !important;
  border: 3px solid white !important;
  width: 100%;
  span span {
    color: red;
    font-size: 0.8em;
    font-weight: 800 !important;
  }
}
.kick-player {
  max-width: 20% !important;
  min-width: 10px !important;
}

.play-button {
  color: white !important;
  font-weight: 600 !important;
  background-color: blue !important;
  margin: 10px 0 !important;
  border-radius: 10px !important;
}
.input {
  color: white !important;
  font-weight: 600 !important;
  background-color: white !important;
  margin: 10px 0 !important;
 // border: 0.5px solid black !important;
  border-radius: 20px !important;
  width: 100%;
}

.back-button {
  background-color: white !important;
  color: darkgreen !important;
  font-weight: 400 !important;
  margin: 30px 0 30px 0 !important;
  border-radius: 10px !important;
}
.copytoclipboard {
  text-align: center;
  color: red;
  width: 100%;
  margin: 0;
}


.result-header {
  color: #ffffff;
  font-weight: 300;
  font-size: 2em;
  //letter-spacing: 0.1em;
  padding: 30px 0 20px 0;
  margin: 0;
}
.result-question {
  color: #ffffff;
  font-weight: 500;
  font-size: 1em;

  padding: 0;
  margin: 10px 10px 10px 10px;
}

.what-would-they-say {
  margin: 5px 10px 5px 10px;
  padding: 0;
  color: #ffffff;
  font-weight: 300;
  font-size: 1em;
}
.the-answer {
  margin: 2px 10px 10px 10px;
  padding: 0;
  color: #fbff08;
  font-weight: 500;
  font-size: 2em;
}

.question {
  margin: 10px 10px;
  padding: 0;
  color: #ffffff;
  font-weight: 500;
  font-size: 1em;
}


.option-button {
  width: 40%;
  height: 130px;
  margin: 10px !important;
  font-size: 0.8em !important;
  background: rgb(30,83,210);
  background: radial-gradient(circle, rgba(30,83,210,1) 0%, rgba(31,11,117,1) 98%, rgba(70,52,134,1) 100%);
  padding: 0 10px !important;
  float: left;
}

.option-button-selected {
  width: 40%;
  height: 130px;
  margin: 10px !important;
  font-size: 0.8em !important;
  background: rgb(30,83,210);
  background: radial-gradient(circle, rgba(255,83,210,1) 0%, rgba(255,11,117,1) 98%, rgba(70,52,134,1) 100%);
  padding: 0 10px !important;
  border-radius: 50px;
  float: left;
}



.correct {
  font-size: 1.2em;
  color: blue;
  background-color: yellow;
  margin: 2px;
  font-weight: 500;
  padding: 3px 10px;
  border-radius: 20px;
  border: 3px solid white;
}

.incorrect {
  font-size: 1.2em;
  color: yellow;
  background-color: red;
  margin: 2px;
  font-weight: 500;
  padding: 3px 10px;
  border-radius: 20px;
  border: 3px solid white;
}


.center {
  text-align: center;
}



h1 {
  font-family: "Roboto";
  text-align: center;
  margin-bottom: 40px;
}


.winner {
  color: yellow;
  font-size: 1.5em;
  font-weight: 800;
  margin: 0;
}
.player {
  color: rgb(213, 252, 222);
  font-size: 1.2em;
  font-weight: 600;
  margin: 0;
}

.countdown-clock {
  margin: auto;
  width: 80px;
  colour: black;
}

.timer-wrapper {
  display: flex;
  justify-content: center;
}

.time-wrapper {
  position: relative;
  width: 80px;
  height: 60px;
  font-size: 2em;
  color: white;
 // font-family: "Montserrat";
}

.time-wrapper .time {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(0);
  opacity: 1;
  transition: all 0.2s;
}

.time-wrapper .time.up {
  opacity: 0;
  transform: translateY(-100%);
}

.time-wrapper .time.down {
  opacity: 0;
  transform: translateY(100%);
}
